import { useEffect, useMemo, useState } from "react";
import { useAssistants } from "../Assistants";

import { CAMPAIGN_TYPE } from "./SingleContact";

const ASSISTANT_CAMPAIGN_MAPPING = {
  [CAMPAIGN_TYPE.SMS]: "sms",
  [CAMPAIGN_TYPE.CALL]: "voice",
  [CAMPAIGN_TYPE.WHATSAPP]: "whatsapp",
};

function useAssistantOptions(assistants, campaignType) {
  const [assistantId, setAssistantId] = useState("");

  const assistantOptions = useMemo(() => {
    const defaultOption = {
      id: 0,
      label: "Please select assistant",
      value: "",
    };

    if (assistants && campaignType) {
      const filteredAssistantsByCampaignType = assistants.filter(
        (assistant) =>
          assistant.assistantType === ASSISTANT_CAMPAIGN_MAPPING[campaignType]
      );

      const filteredAssistantOptions = filteredAssistantsByCampaignType.map(
        (_assistant, idx) => {
          return {
            id: idx + 1,
            label: _assistant.name,
            value: _assistant._id,
          };
        }
      );

      return [defaultOption, ...filteredAssistantOptions];
    }

    return [defaultOption];
  }, [assistants, campaignType]);

  return {
    assistantOptions,
    assistantId,
    setAssistantId,
  };
}

export { useAssistantOptions };
