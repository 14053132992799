import { useState, useEffect } from "react";

import { useNavigate, useOutletContext } from "react-router-dom";
import { Button } from "V2.0/common/button";
import { CreateDialog } from "./create/CreateDialog";
import httpService from "V2.0/services/httpService";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableHeader,
} from "V2.0/common/table";
import { formatTimestamp } from "../Threads/components/conversation_messages";
import { Badge } from "V2.0/common/badge";
import { PencilIcon } from "@heroicons/react/24/outline";

function WorkflowList(props) {
  const { workflows } = props;
  const navigate = useNavigate();

  if (workflows.length) {
    return (
      <Table className="">
        <TableHead>
          <TableHeader>Name</TableHeader>
          <TableHeader>Description</TableHeader>
          <TableHeader>Status</TableHeader>
          <TableHeader>Created At</TableHeader>
          <TableHeader>Updated At</TableHeader>
        </TableHead>
        <TableBody>
          {workflows.map((workflow, idx) => {
            const { _id, name, description, isActive, createdAt, updatedAt } =
              workflow;
            return (
              <TableRow
                className="hover:bg-gray-100 cursor-pointer"
                onClick={() =>
                  navigate(`/pipelines/${_id}`, { state: workflow })
                }
              >
                <TableCell>{name}</TableCell>
                <TableCell>{description}</TableCell>
                <TableCell>
                  <Badge color={isActive ? "emerald" : "orange"}>
                    {isActive ? "Active" : "Inactive"}
                  </Badge>
                </TableCell>
                <TableCell>{formatTimestamp(createdAt)}</TableCell>
                <TableCell>{formatTimestamp(updatedAt)}</TableCell>
                <TableCell>
                  <Button
                    outline
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/pipelines/${_id}/edit`);
                    }}
                  >
                    <PencilIcon className="size-5" />
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }
}

export function Workflows() {
  const { projectId } = useOutletContext();
  const [workflows, setWorkflows] = useState([]);

  const fetchAllWorkflows = async () => {
    try {
      const response = await httpService.get(
        `/pipelines/getPipelines/${projectId}`
      );
      const data = response.data;
      setWorkflows(data.workflows);
    } catch (error) {
      console.error(error);
    }
  };

  const isNameAlreadyExists = (name) => {
    return workflows.some(
      (workflow) => workflow.name.toLowerCase() === name.toLowerCase()
    );
  };

  useEffect(() => {
    if (projectId) {
      fetchAllWorkflows();
    }
  }, [projectId]);

  return (
    <div className="">
      <div className="flex justify-between p-4 border-b items-center">
        <p className="text-md font-semibold">Pipelines</p>
        <div>
          <CreateDialog
            isNameAlreadyExists={isNameAlreadyExists}
            projectId={projectId}
          />
        </div>
      </div>
      <div>
        <WorkflowList workflows={workflows} />
      </div>
    </div>
  );
}
