import {
  ReactFlow,
  useNodesState,
  useEdgesState,
  Background,
  Controls,
  getIncomers,
  getOutgoers,
  getConnectedEdges,
  useReactFlow,
} from "@xyflow/react";
import { useState, createContext, useContext, useMemo, useEffect } from "react";
import {
  PhoneIcon,
  ChatBubbleLeftIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";
import Drawer from "V2.0/components/Drawer";
import { Badge, BadgeButton } from "V2.0/common/badge";
import { FaWhatsapp } from "react-icons/fa";
import { NODE_SETTINGS } from "./nodesSettings";
import { nanoid } from "nanoid";
import { useBuilder } from "../Builder";
import useLayout from "./useLayout";
import useAutoLayout from "./useAutoLayout";
import { StartEdge, CustomEdge } from "./edges";
import { CustomNode } from "./nodes/index1";
import { PlaceholderNode } from "./nodes/Placeholder";
import { SimpleEdge } from "./edges/SimpleEdge";

const NODE_STATUS = {
  PENDING: "pending",
  IN_PROGRESS: "in_progress",
  COMPLETED: "completed",
  FAILED: "failed",
  SKIPPED: "skipped",
  WAITING_LONG_DELAY: "waiting_long_delay",
  BLOCKED: "blocked",
};

const NODE_STATUS_COLORS = {
  [NODE_STATUS.PENDING]: "yellow",
  [NODE_STATUS.IN_PROGRESS]: "orange",
  [NODE_STATUS.COMPLETED]: "green",
  [NODE_STATUS.FAILED]: "red",
  [NODE_STATUS.SKIPPED]: "pink",
  [NODE_STATUS.WAITING_LONG_DELAY]: "orange",
  [NODE_STATUS.BLOCKED]: "red",
};

const nodeTypes = {
  custom: CustomNode,
  placeholder: PlaceholderNode,
};

const edgeTypes = {
  customEdge: CustomEdge,
  simpleEdge: SimpleEdge,
};

const defaultNodes = [
  {
    id: "placeholder",
    type: "placeholder",
    position: { x: 0, y: 0 },
  },
];

const defaultEdges = [];

function generateNodesAndEdges(workflow) {
  const { nodes, edges } = workflow || [];

  if (nodes.length === 0) {
    console.log({ nodes });
    return {
      initialNodes: defaultNodes,
      initialEdges: defaultEdges,
    };
  } else {
    const transformedNodes = nodes.map((node, idx) => {
      const { nodeId, settings, communicationType, nodeType } = node;
      return {
        id: nodeId,
        data: {
          settings: {
            ...settings,
          },
          nodeType,
          ...(communicationType ? { communicationType } : {}),
          status: []
        },
        type: "custom",
        position: { x: 0, y: 0 },
      };
    });

    const firstNode = nodes[0];

    const placeholderNodeEdge = {
      id: `placeholder->${firstNode.nodeId}`,
      source: "placeholder",
      target: firstNode.nodeId,
      type: "smoothstep",
    };

    const transformedEdges = edges.map((edge) => {
      const { from, to, condition } = edge;
      return {
        id: `${from}->${to}`,
        source: from,
        target: to,
        type: "customEdge",
        data: {
          status: condition.split("=")[1],
        },
      };
    });

    console.log({ transformedNodes });

    return {
      initialNodes: transformedNodes,
      initialEdges: transformedEdges,
    };
  }
}

function getSubtreeNodes(graph, nodeId) {
  const visited = new Set();
  const stack = [nodeId];
  const subtreeNodeIds = [];

  // Traverse the graph in DFS manner to get all node IDs in the subtree
  while (stack.length > 0) {
    const node = stack.pop();

    if (!visited.has(node)) {
      visited.add(node); // Mark the node as visited
      subtreeNodeIds.push(node); // Add nodeId to the list of nodes in the subtree

      // Ensure that each node's adjacency list is an array (even if empty)
      const neighbors = graph[node] || []; // Default to empty array if no neighbors exist

      // Push all connected neighbors (children) to the stack
      for (const neighbor of neighbors) {
        if (!visited.has(neighbor)) {
          stack.push(neighbor); // Add the neighbor to the stack to visit
        }
      }
    }
  }

  return subtreeNodeIds; // Return the actual node objects in the subtree
}

// Function to remove a subtree from nodes and edges
function removeSubtree(nodes, edges, rootNodeId) {
  // Step 1: Build the adjacency list (graph representation)
  const graph = {};
  nodes.forEach((node) => {
    graph[node.id] = [];
  });
  edges.forEach(({ source, target }) => {
    graph[source].push(target);
  });

  console.log({ graph });

  // Step 2: Find all nodes in the subtree rooted at rootNodeId
  const subtreeNodes = getSubtreeNodes(graph, rootNodeId);
  console.log({ subtreeNodes });

  // Step 3: Remove all nodes in the subtree from the nodes array
  const updatedNodes = nodes.filter((node) => !subtreeNodes.includes(node.id));

  // Step 4: Remove all edges involving any node in the subtree
  const updatedEdges = edges.filter(({ source, target }) => {
    return !subtreeNodes.includes(source) && !subtreeNodes.includes(target);
  });

  return {
    updatedNodes,
    updatedEdges,
  };
}

const WorkflowBuilderContext = createContext({});
export const useWorkflowBuilder = () => {
  return useContext(WorkflowBuilderContext);
};

export function WorkflowBuilders(props) {
  const { workflow, projectId } = props;
  const { initialNodes, initialEdges } = generateNodesAndEdges(workflow);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [panel, setPanel] = useState({ isOpen: false });
  const { notifyChange } = useBuilder();

  const openPanel = (data) => setPanel({ isOpen: true, ...data });
  const closePanel = () => setPanel({ isOpen: false });

  useLayout();

  const updatedNodes = useMemo(
    () =>
      nodes.map((node) => {
        return {
          ...node,
          data: {
            ...node.data,
            onDelete: () => {
              console.log("delete");
              handleDeleteNode(node);
            },
          },
        };
      }),
    [nodes]
  );

  const updatedEdges = useMemo(
    () =>
      edges.map((edge) => {
        console.log({ edge });
        return {
          ...edge,
          data: {
            ...edge.data,
          },
        };
      }),
    [edges]
  );

  const handleAddNode = (node) => {
    notifyChange();

    const isStartNode = panel.data.isStartNode;
    const status = panel.data.status;
    const id = panel.data.id;

    if (isStartNode) {
      const newNode = {
        ...node,
        data: { ...node.data, isStartNode, status: [] },
        id: nanoid(),
        position: {
          x: 0,
          y: 0,
        },
      };

      setNodes([newNode]);
      openPanel({
        mode: "Configure",
        data: newNode,
      });
      return;
    }

    const newNode = {
      ...node,
      data: {
        ...node.data,
        status: [],
      },
      id: nanoid(),
      position: {
        x: panel.data.position.x,
        y: panel.data.position.y + 200,
      },
    };

    const newEdge = {
      id: `${id}->${newNode.id}`,
      source: id,
      target: newNode.id,
      type: "customEdge",
      data: {
        status,
      },
    };

    setEdges((edges) => {
      const newEdges = [...edges, newEdge];
      return newEdges;
    });

    setNodes((nodes) => {
      const updatedNodes = nodes.map((node) => {
        if (node.id === id) {
          const newNode = {
            ...node,
            data: {
              ...node.data,
              status: [...node.data.status, status],
            },
          };
          return newNode;
        } else {
          return node;
        }
      });
      const newNodes = [...updatedNodes, newNode];
      console.log({ updatedNodes, newNodes });
      return newNodes;
    });

    setPanel({
      isOpen: true,
      mode: "Configure",
      data: newNode,
    });
  };

  const handleMultipleDelete = (deletedNode) => {
    const { updatedNodes, updatedEdges } = removeSubtree(
      nodes,
      edges,
      deletedNode.id
    );

    if (updatedNodes.length === 0) {
      setNodes(initialNodes);
    } else {
      setNodes(updatedNodes);
    }

    setEdges(updatedEdges);
  };

  const handleDeleteNode = (deletedNode) => {
    const incomers = getIncomers(deletedNode, nodes, edges); // incomers are the nodes which connect to the deleted node
    const outgoers = getOutgoers(deletedNode, nodes, edges); // outgoers are the nodes which can be reached from the deleted node
    const connectedEdges = getConnectedEdges([deletedNode], edges); // there will be an edge in which the deleted node is a target and another where
    // this node is a source.

    if (outgoers.length > 1) {
      handleMultipleDelete(deletedNode);
      return;
    }

    const remainingEdges = edges.filter(
      (edge) => !connectedEdges.includes(edge)
    ); // remove all the edges which includes the deleted node in its source/target

    const createdEdges = incomers.flatMap((incomer) => {
      console.log({ incomer });
      const { id: source } = incomer;
      const { data } = connectedEdges[0];
      return outgoers.map(({ id: target }) => {
        return {
          id: `${source}->${target}`,
          source,
          target,
          type: "customEdge",
          data,
        };
      });
    });

    const edgeConnectedToDeletedNode = connectedEdges.find(
      (edge) => edge.target === deletedNode.id
    );
    const updatedNodes = nodes.reduce((acc, node) => {
      if (node.id === edgeConnectedToDeletedNode?.source) {
        const newNode = {
          ...node,
          data: {
            ...node.data,
            status: node.data.status.filter(
              (status) => status != edgeConnectedToDeletedNode.data.status
            ),
          },
        };
        return [...acc, newNode]
      }

      if (node.id != deletedNode.id) {
        return [...acc, node];
      }

      return acc;
    }, []); // removing the deleted node

    const updatedEdges = [...remainingEdges, ...createdEdges];

    if (updatedNodes.length === 0) {
      setNodes(initialNodes);
    } else {
      setNodes(updatedNodes);
    }
    setEdges(updatedEdges);
  };

  const handleNodeClick = (e, node) => {
    if (node.id === "placeholder") {
      openPanel({ mode: "add", data: { isStartNode: true } });
      return;
    }

    openPanel({
      mode: "Configure",
      data: node,
    });
  };

  return (
    <WorkflowBuilderContext.Provider
      value={{
        openPanel,
        isPanelOpen: panel.isOpen,
      }}
    >
      <ReactFlow
        nodes={updatedNodes}
        edges={updatedEdges}
        onNodeClick={handleNodeClick}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        // onEdgeClick={handleEdgeClick}
        nodesDraggable={false}
        fitView
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        proOptions={{ hideAttribution: true }}
        minZoom={0.5}
        zoomOnScroll
        panOnScroll
      >
        <Background />
        {/* <Controls /> */}
      </ReactFlow>
      {panel.isOpen && (
        <Drawer open={panel.isOpen} onClose={closePanel} title="Configuration">
          {panel.mode === "add" && (
            <div className="flex flex-col gap-y-4">
              <div className="flex flex-col gap-y-2">
                <p className="text-sm font-semibold text-gray-500">Action</p>
                <div className="flex flex-col gap-y-2">
                  <div
                    onClick={() =>
                      handleAddNode({
                        data: {
                          nodeType: "communication",
                          communicationType: "call",
                          settings: {
                            name: "Call",
                            description: "",
                            message: "",
                          },
                          status: [],
                        },
                        selected: true,
                        type: "custom",
                      })
                    }
                    className="flex items-center border rounded px-2 py-1 gap-x-2 cursor-pointer hover:bg-gray-200"
                  >
                    <PhoneIcon className="size-4" /> Call
                  </div>
                  <div
                    onClick={() =>
                      handleAddNode({
                        data: {
                          nodeType: "communication",
                          communicationType: "sms",
                          settings: {
                            name: "Sms",
                            description: "",
                            message: "",
                          },
                          status: [],
                        },
                        selected: true,
                        type: "custom",
                      })
                    }
                    className="flex items-center border rounded px-2 py-1 gap-x-2 cursor-pointer hover:bg-gray-200"
                  >
                    <ChatBubbleLeftIcon className="size-4" /> SMS
                  </div>
                  <div
                    onClick={() =>
                      handleAddNode({
                        data: {
                          nodeType: "communication",
                          communicationType: "whatsapp",
                          settings: {
                            name: "Whatsapp",
                            description: "",
                            template: "",
                          },
                          status: [],
                        },
                        selected: true,
                        type: "custom",
                      })
                    }
                    className="flex items-center border rounded px-2 py-1 gap-x-2 cursor-pointer hover:bg-gray-200"
                  >
                    <FaWhatsapp className="size-4" /> WhatsApp
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-y-2">
                <p className="text-sm font-semibold text-gray-500">Timers</p>
                <div className="flex flex-col gap-y-2">
                  <div
                    onClick={() =>
                      handleAddNode({
                        data: {
                          nodeType: "delay",
                          settings: {
                            name: "Delay",
                            description: "",
                            delaySeconds: "",
                          },
                          status: [],
                        },
                        selected: true,
                        type: "custom",
                      })
                    }
                    className="flex items-center border rounded px-2 py-1 gap-x-2 cursor-pointer hover:bg-gray-200"
                  >
                    <ClockIcon className="size-4" /> Delay
                  </div>
                </div>
              </div>
            </div>
          )}
          {panel.mode === "Configure" && (
            <div className="flex flex-col">
              {NODE_SETTINGS[panel.data.data.nodeType]({
                communicationType: panel.data.data.communicationType,
                node: panel.data,
                setNodes,
                closePanel,
                projectId,
              })}
            </div>
          )}
          {panel.mode === "addPath" && (
            <div className="flex flex-col gap-y-4">
              <div className="flex flex-col gap-y-8">
                <p className="text-sm font-semibold text-gray-800">
                  Select Condition
                </p>
                <div className="flex flex-col gap-y-4">
                  {Object.values(NODE_STATUS)
                    .filter(
                      (status) => !panel.data.data.status.includes(status)
                    )
                    .map((status) => {
                      return (
                        <BadgeButton
                          color={NODE_STATUS_COLORS[status]}
                          id={status}
                          onClick={() => {
                            openPanel({
                              mode: "add",
                              data: {
                                ...panel.data,
                                status,
                              },
                            });
                          }}
                        >
                          {status}
                        </BadgeButton>
                      );
                    })}
                </div>
              </div>
            </div>
          )}
        </Drawer>
      )}
    </WorkflowBuilderContext.Provider>
  );
}
