import { Button } from "V2.0/common/button";
import { XMarkIcon } from "@heroicons/react/16/solid";

export function DialogTitleWithClose({title, onClose}) {
  return (
    <div className="text-lg flex flex-row justify-between">
      <div className="font-medium">{title}</div>
      <div className="text-lg">
        <Button plain onClick={onClose}>
          <XMarkIcon />
        </Button>
      </div>
    </div>
  );
}
