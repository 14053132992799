import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import Form from "../../components/Form";
import * as thirdPartyServices from "../../services/thirdpartyService";
import { message } from "antd";
import Twilio from "../../images/twilio.svg";

function TwilioComponent({ projectId }) {
  const [formFieldforTwilio, setFormFieldForTwilio] = useState({
    twilioAccountSID: "",
    twilioAuthToken: "",
  });

  const [errorMessage] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (projectId) {
          const response = await thirdPartyServices.getthirdpartyData(
            projectId
          );
          const { twilioAccountSID, twilioAuthToken } =
            response.data.telephonySettings.twilioSettings;

          const twilioSettings = {
            twilioAccountSID,
            twilioAuthToken,
          };
          setFormFieldForTwilio(twilioSettings);
        }
      } catch (error) {}
    };
    fetchData();
  }, [projectId]);

  const [loading, setLoading] = useState(false);

  const templateTwilio = [
    {
      name: "twilioAccountSID",
      label: "Account SID",
      type: "text",
      style: "",
    },
    {
      name: "twilioAuthToken",
      label: "Auth Token",
      type: "password",
      style: "",
    },
  ];

  const buttonInfoTwilio = {
    label: "Connect Twilio",
    style: "entire-width",
  };

  const schemaTwilio = {
    twilioAccountSID: Joi.string().max(5000).required().label("Account SID"),
    twilioAuthToken: Joi.string().max(5000).required().label("Auth SID"),
  };

  const handleTwilioConnection = async (data) => {
    let telephonySettings = {
      telephonySettings: {
        telephonyProvider: "twilio",
        twilioSettings: {
          twilioAccountSID: data.twilioAccountSID,
          twilioAuthToken: data.twilioAuthToken,
        },
      },
    };

    try {
      const response = await thirdPartyServices.thirdpartyUpdate(
        telephonySettings,
        projectId
      );
      if (response.status === 200) {
        message.success("Twilio connected");
        setLoading(false);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 500
      ) {
        message.error(ex.response.data.msg);
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <div class="flex flex-col items-center">
        <div className="w-full max-w-md p-8 border rounded-lg">
          <div className="flex gap-x-2 mb-4">
            <img src={Twilio} width={24} />
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              Connect Twilio account
            </h3>
          </div>

          <div>
            <Form
              key={JSON.stringify(formFieldforTwilio)}
              errorMessage={errorMessage}
              inputField={formFieldforTwilio}
              template={templateTwilio}
              schema={schemaTwilio}
              buttonInfo={buttonInfoTwilio}
              onSubmit={handleTwilioConnection}
              loading={loading}
              setLoading={setLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TwilioComponent;
