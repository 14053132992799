import { WhatsAppSettings } from "./WhatsAppSettings";
import { EmailSettings } from "./EmailSettings";
import { SmsSettings } from "./SmsSettings";
import { CallSettings } from "./CallSettings";

export const COMMUNICATION_NODE_SETTINGS = {
  whatsapp: (props) => <WhatsAppSettings {...props} />,
  email: (props) => <EmailSettings {...props} />,
  sms: (props) => <SmsSettings {...props} />,
  call: (props) => <CallSettings {...props} />,
};
