import React, { useState } from "react";
import { message } from "antd";
import Joi from "joi-browser";
import Form from "../../components/Form";
import * as knowledgebaseService from "../../services/knowledgebaseService";
import { addGSheet } from "../Knowledge_base/service";

function AddSpreadsheet({ projects, activeProject, onClose, refetch, KB }) {
  const [inputField] = useState({
    url: ""
  });

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  const template = [
    {
      name: "url",
      label: "Google Sheet link",
      description: `Add 'superdash@superdash-382709.iam.gserviceaccount.com' as 'viewer' to the Google Sheet.`,
      type: "text",
    }
  ];

  const buttonInfo = { label: "Upload data", style: "entire-width" };
  // const buttonInfo1 = { label: " Create A", style: "" };

  const schema = {
    url: Joi.string().uri().min(2).max(500).required().label("URL")
  };

  const handleSubmit = async (data) => {
    {
      try {
        const response = await addGSheet(
          KB._id,
          projects[activeProject]._id,
          data
        );
        if (response.status === 200) {
          message.success("Success. Your file has now been queued.")
            setLoading(false);
            refetch(projects[activeProject]._id, KB._id);
            onClose();

        }
      } catch (ex) {
        if (
          ex.response &&
          ex.response.status >= 400 &&
          ex.response.status <= 500
        ) {
          setLoading(false);
          message.error(ex.response.data.msg);
          return;
        }
      }
    }
  };

  const handleTemplate = (string) => {
    if (string === "1")
      window.open(
        "https://docs.google.com/spreadsheets/d/1Il3HA1afnwwCDYkGqCz8NTIlqDPfPVtOfzR6sAHuCeE/edit?usp=sharing",
        "_blank"
      );

    if (string === "2")
      window.open(
        "https://docs.google.com/spreadsheets/d/1iP-GE4V4ubNZUd2x2WGMD9LuAJXu5bdjtyjq8bpMTVc/edit#gid=0",
        "_blank"
      );
  };

  return (
    <Form
      errorMessage={errorMessage}
      inputField={inputField}
      template={template}
      schema={schema}
      buttonInfo={buttonInfo}
      onSubmit={handleSubmit}
      loading={loading}
      setLoading={setLoading}
    />
  );
}

export default AddSpreadsheet;
