import http from "V2.0/services/httpService";

const endPoint: string = "/knowledgebase";

function getKnowledgebases({ projectId }) {
  return http.get(`${endPoint}/list/${projectId}`);
}

function createKnowledgebase({
  projectId,
  payload,
}: {
  projectId: string;
  payload: {label: string};
}) {
  return http.post(`${endPoint}/createKnowledgeBase/${projectId}`, payload);
}

function deleteKnowledgebase({
  projectId,
  _id,
}: {
  projectId: string;
  _id: string;
}) {
  return http.delete(`${endPoint}/deleteKnowlegeBase/${projectId}/${_id}`);
}


function getKnowledgebase({ projectId, knowledgebaseId }) {
  return http.get(`${endPoint}/getKnowledgebase/${projectId}/${knowledgebaseId}`);
}

function addParagraph({ projectId, knowledgebaseId , payload}) {
  return http.post(`${endPoint}/addParagraph/${projectId}/${knowledgebaseId}`, payload);
}

function updateParagraph({ projectId, knowledgebaseId , payload}) {
  return http.put(`${endPoint}/updateParagraph/${projectId}/${knowledgebaseId}`, payload);
}

function deleteParagraph({ projectId, knowledgebaseId , payload}) {
  return http.delete(`${endPoint}/deleteParagraph/${projectId}/${knowledgebaseId}`, payload);
}



function addURLToKB(workspaceId: string, kbId: string, obj:any) {
  return http.post(`${endPoint}/urlist/${workspaceId}/${kbId}`, obj);
}

function deleteCorpusByEtag(workspaceId: string, kbId: string, etag:string) {
  return http.delete(`${endPoint}/${workspaceId}/${kbId}/${etag}`, );
}

function docsUpload(workspaceId: string ,kbId: string, formData) {
  return http.post(`${endPoint}/upload/${workspaceId}/${kbId}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

function addGSheet(kbId: string, workspaceId: string, payload: { url: string}) {

  
  // const params = new URLSearchParams({
  //   url: url,
  // })


  return http.post(`${endPoint}/addSheet/${workspaceId}/${kbId}`, payload );
}

function syncGSheet( kbId: string, workspaceId: string, payload: {eTag: string}) {
   


  return http.post(`${endPoint}/syncSheet/${workspaceId}/${kbId}`, payload );
}

function deleteCorpus( kbId: string, workspaceId: string, eTag: string) {
  return http.delete(`${endPoint}/deleteCorpus/${workspaceId}/${kbId}/${eTag}`);
}

function getDocumentDownloadLink(workspaceId, kbId, etag) {
  return http.get(`${endPoint}/getDocumentDownloadLink/${workspaceId}/${kbId}/${etag}`);
}



export { 
  getKnowledgebases, 
  getKnowledgebase, 
  createKnowledgebase, 
  deleteKnowledgebase, 
  addParagraph, 
  updateParagraph, 
  deleteParagraph, 
  addURLToKB, 
  deleteCorpusByEtag, 
  docsUpload,
  syncGSheet, 
  addGSheet,
  deleteCorpus,
  getDocumentDownloadLink };
