import {
  Dialog,
  DialogTitle,
  DialogDescription,
  DialogBody,
  DialogActions,
} from "V2.0/common/dialog";
import { Button } from "V2.0/common/button";

import { useEffect, useState } from "react";
import { PlusIcon } from "@heroicons/react/24/outline";

import { ErrorMessage, Field, Label } from "V2.0/common/fieldset";
import { Input } from "V2.0/common/input";
import httpService from "V2.0/services/httpService";
import { Textarea } from "V2.0/common/textarea";
import { useNavigate } from "react-router-dom";

export function CreateDialog(props) {
  const { isNameAlreadyExists, projectId } = props;

  const [open, setOpen] = useState(false);
  const [workflowName, setWorkflowName] = useState("");
  const [workflowDescription, setWorkflowDescription] = useState("");
  const [errors, setErrors] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      setWorkflowName("");
      setErrors("");
    };
  }, [open]);

  const handleWorkflowNameChange = (event) => {
    setErrors(null);
    const name = event.target.value;
    setWorkflowName(name);

    if (isNameAlreadyExists(name)) {
      setErrors({ message: "Name already taken!" });
      return;
    }
  };

  const handleWorkflowDescriptionChange = (event) => {
    const description = event.target.value;
    setWorkflowDescription(description);
  };

  const handleCreateWorkflow = async () => {
    try {
      const payload = {
        name: workflowName,
        description: workflowDescription,
        nodes: [],
        edges: []
      };
      const response = await httpService.post(
        `/pipelines/createPipeline/${projectId}`,
        payload
      );

      if (response.status === 201) {
        navigate(`/pipelines/${response.data._id}/edit`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const isNameEmpty = workflowName === "";
  const isPrimaryActionDisabled = isNameEmpty || errors?.message;

  return (
    <>
      <Button outline onClick={() => setOpen(true)}>
        <PlusIcon /> Create Pipeline
      </Button>
      <Dialog open={open} onClose={() => {}}>
        <DialogTitle>New Pipeline</DialogTitle>
        <DialogBody>
          <div className="flex flex-col gap-y-4">
            <Field>
              <Label>Pipeline Name</Label>
              <Input
                type="text"
                value={workflowName}
                onChange={handleWorkflowNameChange}
              />
              {errors?.message && <ErrorMessage>{errors.message}</ErrorMessage>}
            </Field>
            <Field>
              <Label>Description</Label>
              <Textarea
                value={workflowDescription}
                onChange={handleWorkflowDescriptionChange}
              />
            </Field>
          </div>
        </DialogBody>
        <DialogActions>
          <Button outline onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleCreateWorkflow} disabled={isPrimaryActionDisabled}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
