import { Textarea as HeadlessTextarea } from '@headlessui/react';
import { clsx } from 'clsx';
import { forwardRef, useState, useEffect } from 'react';
import { Badge, BadgeButton } from './badge';

export const Textarea = forwardRef(function Textarea({ className, resizable = true, height, ...props }, ref) {
  const [text, setText] = useState('');
  const [badges, setBadges] = useState([]);

  useEffect(() => {
    const pattern = /\/([^\/]+)\//g; // Matches text like /example/
    let match;
    const names = [];
    while ((match = pattern.exec(text)) !== null) {
      names.push(match[1]);
    }
    setBadges(names);
  }, [text]);

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  return (
    <div
      className={clsx([
        className,
        'relative block w-full',
        // Additional styles here
      ])}
    >
      {badges.map((name, index) => (
        <Badge key={index} name={name} color="blue" />
      ))}
      <HeadlessTextarea
        ref={ref}
        value={text}
        onChange={handleTextChange}
        style={{ height }}
        className={clsx([
          'relative block w-full',
          'appearance-none rounded',
          'px-3 py-2',
          'text-base text-gray-700',
          'border border-gray-300',
          'focus:outline-none focus:ring-1 focus:ring-blue-500',
          resizable ? 'resize-y' : 'resize-none',
          // Additional styles here
        ])}
        {...props}
      />
    </div>
  );
});


