import http from "V2.0/services/httpService";

const endPoint: string = "/assistants";

const ROUTES = {
  ASSISTANTS: "/assistants",

  /* PUT */
  MODEL_SETTINGS: "/assistants/modelSettings",
  PROMPT_SETTINGS: "/assistants/promptSettings",
  RAG_SETTINGS: "/assistants/ragSettings",
  EXTRACTIONS: "/assistants/extractions",
  REAL_TIME_BOOKINGS: "/assistants/realTimeBookingSettings",
  CUSTOM_APIS: "assistants/customApis",
  TIMEZONE_SETTINGS: "assistants/timezoneSettings",
  TRANSFER_SETTINGS: "assistants/transferSettings",
  MESSAGING_SETTINGS: "assistants/messagingSettings",
  COMMUNICATION_BINDINGS: "/assistants/communicationBindings",
  VOICE_SETTINGS: "assistants/voiceSettings",
  TRANSCRIPTION_SETTINGS: "assistants/transcriptionSettings",
  TTS_SETTINGS: "assistants/ttsSettings",

  /* PUT POST DELETE */
  CUSTOM_ACTIONS: "assistants/customActions",
  AGENTS: "assistants/agents",
  CUSTOM_APIS_ARRAY: "assistants/customApisArray",
  CUSTOM_ACTIONS_ARRAY: "assistants/customActionsArray",
};

function getAssistants({ projectId }) {
  return http.get(`${endPoint}/getAssistants/${projectId}`);
}

function createAssistant({
  projectId,
  payload,
}: {
  projectId: string;
  payload: { name: string; description: string; assistantType: string };
}) {
  return http.post(`${endPoint}/createAssistant/${projectId}`, payload);
}

function removeAssistant({ workspaceId, assistantId }) {
  return http.delete(
    `${ROUTES.ASSISTANTS}/deleteAssistant/${workspaceId}/${assistantId}`
  );
}

function getAssistant({ workspaceId, assistantId }) {
  return http.get(`${endPoint}/getAssistant/${workspaceId}/${assistantId}`);
}

function updateAssistant({ projectId, assistantId, payload }) {
  return http.put(
    `${endPoint}/updateAssistant/${projectId}/${assistantId}`,
    payload
  );
}

function getModelSettings({ workspaceId, assistantId }) {
  return http.get(`${ROUTES.MODEL_SETTINGS}/${workspaceId}/${assistantId}`);
}

function putModelSettings({ workspaceId, assistantId, payload }) {
  return http.put(
    `${ROUTES.MODEL_SETTINGS}/${workspaceId}/${assistantId}`,
    payload
  );
}

function putCommunicationBinding({
  workspaceId,
  assistantId,
  payload,
}: {
  workspaceId: string;
  assistantId: string;
  payload: CommunicationBinding;
}) {
  return http.put(
    `${ROUTES.COMMUNICATION_BINDINGS}/${workspaceId}/${assistantId}`,
    payload
  );
}

function deleteCommunicationBinding({
  workspaceId,
  assistantId,
  communicationBindingId,
}) {
  return http.delete(
    `${ROUTES.COMMUNICATION_BINDINGS}/${workspaceId}/${assistantId}/${communicationBindingId}`
  );
}

function putTTSSettings({ workspaceId, assistantId, payload }) {
  return http.put(
    `${ROUTES.TTS_SETTINGS}/${workspaceId}/${assistantId}`,
    payload
  );
}

function putTranscriptionSettings({ workspaceId, assistantId, payload }) {
  return http.put(
    `${ROUTES.TRANSCRIPTION_SETTINGS}/${workspaceId}/${assistantId}`,
    payload
  );
}

function putCustomApis({ workspaceId, assistantId, payload }) {
  return http.put(
    `${ROUTES.CUSTOM_APIS}/${workspaceId}/${assistantId}`,
    payload
  );
}

function postCustomApisArray({ workspaceId, assistantId, payload }) {
  return http.post(
    `${ROUTES.CUSTOM_APIS_ARRAY}/${workspaceId}/${assistantId}`,
    payload
  );
}

function getCustomApisArray({ workspaceId, assistantId }) {
  return http.get(`${ROUTES.CUSTOM_APIS_ARRAY}/${workspaceId}/${assistantId}`);
}

function deleteCustomApisArray({ workspaceId, assistantId, customApiId }) {
  return http.delete(
    `${ROUTES.CUSTOM_APIS_ARRAY}/${workspaceId}/${assistantId}/${customApiId}`
  );
}

function putCustomApisArray({
  workspaceId,
  assistantId,
  customApiId,
  payload,
}) {
  return http.put(
    `${ROUTES.CUSTOM_APIS_ARRAY}/${workspaceId}/${assistantId}/${customApiId}`,
    payload
  );
}

function putExtraction({ workspaceId, assistantId, payload }) {
  return http.put(
    `${ROUTES.EXTRACTIONS}/${workspaceId}/${assistantId}`,
    payload
  );
}

function putCustomActions({ workspaceId, assistantId, payload }) {
  return http.put(
    `${ROUTES.CUSTOM_ACTIONS}/${workspaceId}/${assistantId}`,
    payload
  );
}

function putCustomActionsArray({
  workspaceId,
  assistantId,
  customActionId,
  payload,
}) {
  return http.put(
    `${ROUTES.CUSTOM_ACTIONS_ARRAY}/${workspaceId}/${assistantId}/${customActionId}`,
    payload
  );
}

function deleteCustomActionsArray({
  workspaceId,
  assistantId,
  customActionId,
}) {
  return http.delete(
    `${ROUTES.CUSTOM_ACTIONS_ARRAY}/${workspaceId}/${assistantId}/${customActionId}`
  );
}

function postCustomActionsArray({ workspaceId, assistantId, payload }) {
  return http.post(
    `${ROUTES.CUSTOM_ACTIONS_ARRAY}/${workspaceId}/${assistantId}`,
    payload
  );
}

function putVoiceSettings({ workspaceId, assistantId, payload }) {
  return http.put(
    `${ROUTES.VOICE_SETTINGS}/${workspaceId}/${assistantId}`,
    payload
  );
}

function putMessagingSettings({ workspaceId, assistantId, payload }) {
  return http.put(
    `${ROUTES.MESSAGING_SETTINGS}/${workspaceId}/${assistantId}`,
    payload
  );
}

function putTimezoneSetting({ workspaceId, assistantId, payload }) {
  return http.put(
    `${ROUTES.TIMEZONE_SETTINGS}/${workspaceId}/${assistantId}`,
    payload
  );
}

function putTransferSettings({ workspaceId, assistantId, payload }) {
  return http.put(
    `${ROUTES.TRANSFER_SETTINGS}/${workspaceId}/${assistantId}`,
    payload
  );
}

function postAgent({ workspaceId, assistantId, payload }) {
  return http.post(`${ROUTES.AGENTS}/${workspaceId}/${assistantId}`, payload);
}

function putAgent({ workspaceId, assistantId, agentId, payload }) {
  return http.put(`${ROUTES.AGENTS}/${workspaceId}/${assistantId}/${agentId}`, payload);
}

function deleteAgent({ workspaceId, assistantId, agentId }) {
  return http.delete(
    `${ROUTES.AGENTS}/${workspaceId}/${assistantId}/${agentId}`
  );
}

function putRAGSettings({ workspaceId, assistantId, payload }) {
  return http.put(
    `${ROUTES.RAG_SETTINGS}/${workspaceId}/${assistantId}`,
    payload
  );
}

function putRealTimeBookings({workspaceId, assistantId, payload}) {
  return http.put(
    `${ROUTES.REAL_TIME_BOOKINGS}/${workspaceId}/${assistantId}`, payload
  )
}

export {
  /* Assistants */
  getAssistants,
  createAssistant,
  removeAssistant,
  getAssistant,
  updateAssistant,

  /* Model Settings */
  getModelSettings,
  putModelSettings,
  
  /* TTS Settings */
  putTTSSettings,
  
  /* Transcription Settings */
  putTranscriptionSettings,
  
  /* Communication binding */
  putCommunicationBinding,
  deleteCommunicationBinding,
  
  /* Custom APIs */
  putCustomApis,
  
  /* Custom APIs array */
  postCustomApisArray,
  putCustomApisArray,
  deleteCustomApisArray,
  getCustomApisArray,
  
  /* Extraction */
  putExtraction,

  /* Custom Actions */
  putCustomActions,
  deleteCustomActionsArray,
  putCustomActionsArray,
  postCustomActionsArray,
  
  /* Voice Settings */
  putVoiceSettings,
  
  /* MessagingSettings */
  putMessagingSettings,
  
  /* Timezone settings */
  putTimezoneSetting,
  
  /* Transfer Settings */
  putTransferSettings,
  
  /* Agents */
  postAgent,
  putAgent,
  deleteAgent,
  
  /* RAG Settings */
  putRAGSettings,

  /* Real Time Bookings */
  putRealTimeBookings
};
