import React from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import ProtectedRoute from "./common/protectedRoute";
import Home from "./HomePages/Home";
import "./index.css";
import "@xyflow/react/dist/style.css";
import Login from "./SignupnLogin/Login";
import SignUp from "./SignupnLogin/Signup";
import Logout from "./SignupnLogin/Logout";
import Welcome from "./OnboardingPages/Welcome";
import ThirdPartAuthSuccess from "./HomePages/HomeIntegrations/ThirdPartAuthSuccess";
import PaymentSuccess from "./PaymentPages.js/PaymentSuccess";
import Profile from "./NavBarComponents/Profile";
import { CompanyProjectsProvider } from "./Context/CompanyProjectsContext";
import TeamInvite from "./PublicPages/TeamInvite";
import ResetPassword from "./SignupnLogin/ResetPassword";
import ForgotPassword from "./SignupnLogin/ForgotPassword";

import { Dashboard } from "../V2.0/HomePages/Dashboard/index1";
import { KnowledgeBases, KnowledgeBase } from "./HomePages/Knowledge_base";
import { Integrations } from "./HomePages/Integrations";
import HomePrompt from "./HomePages/HomePrompt";
import HomeCampaigns from "./HomePages/HomeCampaigns";
import HomeModules from "./HomePages/HomeModules";
import HomeIntegrations from "./HomePages/HomeIntegrations";
import HomeAPISettings from "./HomePages/HomeAPISettings";
import { TextKnowledge } from "./HomePages/Knowledge_base/Texts";
import { WebHooks } from "./HomePages/Modules/Webhooks";
// import { HumanAgentTransfer } from './HomePages/Modules/Human_agents_transfer';
import { Extractions } from "./HomePages/Modules/Extractions";
import { Triggers } from "./HomePages/Modules/Triggers";
import Modules from "./HomePages/Modules/index1";

import { CustomAPIs } from "./HomePages/HomeModule/CustomAPIs";
import { Contacts } from "./HomePages/Contacts";
import { NewContact } from "./HomePages/Contacts/New_contact";
import { ViewContact } from "./HomePages/Contacts/View_contact";
import { EditContact } from "./HomePages/Contacts/Edit_contact";
import { Contact } from "./HomePages/Contacts/Contact";
import Schedule from "./HomePages/HomeCampaign/Configuration";
// import HumanAgentTransfer from "./HomePages/HomeModule/HumanAgenTransfer/HumanAgentTransfer";
import { HumanAgentTransfer } from "./HomePages/Modules/Human_agents_transfer";
import { EndCall } from "./HomePages/Modules/End_call";

import { Threads } from "./HomePages/Threads";
import Whatsapp from "./HomePages/Integrations/Whatsapp";
import { TwilioIntegration } from "./HomePages/Integrations/Twilio";
import { MessengerIntegration } from "./HomePages/Integrations/Messenger";
import { S3Integration } from "./HomePages/Integrations/S3";
import { PineConeIntegration } from "./HomePages/Integrations/Pinecone";
import { ChromaDBIntegration } from "./HomePages/Integrations/ChromaDB";
import VoiceEmbed from "./HomePages/HomeModule/VoiceEmbed";
import ChatEmbed from "./HomePages/HomeModule/ChatEmbed";
import { ZohoCRMIntegration } from "./HomePages/Integrations/ZohoCRM";
import { GHLIntegration } from "./HomePages/Integrations/GHL";
import { ZapierIntegration } from "./HomePages/Integrations/Zapier";
import ZohoCRM from "./HomePages/HomeModule/ZohoCRM";
import GHLCRM from "./HomePages/HomeModule/GHLCRM";
import PIIEncryptionComponent from "./HomePages/HomeModule/PIIEncryptionComponent";
import Recording from "./HomePages/HomeModule/Recording";
import AcuityCalendarScheduling from "V2.0/HomePages/HomeModule/Acuity";
import { PlivoIntegration } from "./HomePages/Integrations/Plivo";
import { MakeIntegration } from "./HomePages/Integrations/Make";

import Account from "./Account";
import { GMailIntegraion } from "./HomePages/Integrations/GMail";
import { Workflows } from "./HomePages/Workflows";
import { Workflow } from "./HomePages/Workflows/Workflow";
import { Builder } from "./HomePages/Workflows/Builder";

import { Assistants, Assistant } from "./HomePages/Assistants";

// Define ProtectedRouteWithContext component
const ProtectedRouteWithContext = ({ children }) => (
  <CompanyProjectsProvider>
    <ProtectedRoute>
      <Outlet />
    </ProtectedRoute>
  </CompanyProjectsProvider>
);

function App() {
  return (
    <Routes>
      <Route path="/trysignup" element={<SignUp />} />
      <Route path="/login" element={<Login />} />
      <Route path="/team-invite/:token?" element={<TeamInvite />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route
        path="/onboarding/:companyId"
        element={
          <ProtectedRoute>
            <Welcome />
          </ProtectedRoute>
        }
      />
      <Route element={<ProtectedRouteWithContext />}>
        <Route path="/logout" element={<Logout />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/account" element={<Account />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/oauth/callback" element={<ThirdPartAuthSuccess />} />
        <Route path="/success/:sessionId?" element={<PaymentSuccess />} />
        <Route element={<Home />}>
          <Route index path="/" element={<Navigate to="dashboard" replace />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="knowledgebases" element={<KnowledgeBases />} />
          <Route path="knowledgebases/:knowledgebaseId" element={<KnowledgeBase />} />
          <Route path="prompt" element={<HomePrompt />} />
          <Route path="threads" element={<Threads />} />
          <Route path="pipelines" element={<Workflows />} />
          <Route path="pipelines/:id/edit" element={<Builder />} />
          <Route path="pipelines/:id" element={<Workflow />} />

          <Route path="assistants" element={<Assistants />} />
          <Route path="assistants/:assistantId" element={<Assistant />} />
          
          <Route element={<Contacts />}>
            <Route path="contacts" element={<Contact />} />
          </Route>

          <Route path="campaign" element={<HomeCampaigns />} />
          <Route path="campaign/configuration" element={<Schedule />} />

          {/* <Route path="modules" element={<Modules />}>
            <Route path="custom-api" element={<CustomAPIs />} />
            <Route path="webhooks" element={<WebHooks />} />
            <Route path="agent-transfer" element={<HumanAgentTransfer />} />
            <Route path="extraction" element={<Extractions />} />
            <Route path="triggers" element={<Triggers />} />
            <Route path="end-call" element={<EndCall />} />
            <Route path="voice-embed" element={<VoiceEmbed />} />
            <Route path="chat-embed" element={<ChatEmbed />} />
            <Route path="zoho-crm" element={<ZohoCRM />} />
            <Route path="ghl" element={<GHLCRM />} />
            <Route path="recording" element={<Recording />} />
            <Route path="pii-encryption" element={<PIIEncryptionComponent />} />
            <Route
              path="acuity-scheduling"
              element={<AcuityCalendarScheduling />}
            />
          </Route> */}
          <Route path="integrations" element={<Integrations />} />
          {/* <Route path="integrations/webhooks" element={<WebHooks />} /> */}

          <Route
            path="integrations/zoho-crm"
            element={<ZohoCRMIntegration />}
          />
          <Route path="integrations/ghl" element={<GHLIntegration />} />
          {/* <Route path="integrations/gmail" element={<GMailIntegraion />} /> */}
          <Route path="integrations/whatsapp" element={<Whatsapp />} />
          <Route
            path="integrations/messenger"
            element={<MessengerIntegration />}
          />
          <Route path="integrations/twilio" element={<TwilioIntegration />} />
          <Route path="integrations/plivo" element={<PlivoIntegration />} />

          <Route path="integrations/s3" element={<S3Integration />} />
          <Route
            path="integrations/pinecone"
            element={<PineConeIntegration />}
          />
          <Route
            path="integrations/chromadb"
            element={<ChromaDBIntegration />}
          />
          <Route path="integrations/zapier" element={<ZapierIntegration />} />
          <Route path="integrations/make" element={<MakeIntegration />} />

          <Route path="settings" element={<HomeAPISettings />} />
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="/not-found" replace />} />
    </Routes>
  );
}

export default App;
