import { useEffect, useState, useContext, createContext } from "react";
import { useLocation, useParams, useOutletContext } from "react-router-dom";
import httpService from "V2.0/services/httpService";
import { ReactFlowProvider, useReactFlow } from "@xyflow/react";
import { WorkflowBuilders } from "./Builder/index2";
import { message } from "antd";
import { Button } from "V2.0/common/button";
import { Switch } from "V2.0/common/switch";

const BuilderContext = createContext(null);
export const useBuilder = () => {
  return useContext(BuilderContext);
};

function BuilderProvider({ value, children }) {
  return (
    <BuilderContext.Provider value={value}>{children}</BuilderContext.Provider>
  );
}

function BuilderHeader(props) {
  const { workflow, onSaveWorkflow } = props;
  const { notify } = useBuilder();
  const { getNodes, getEdges } = useReactFlow();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (workflow) {
      setIsActive(workflow.isActive);
    }
  }, [workflow]);

  const transformNodes = (nodes) => {
    let transformedNodes = [];

    for (let node of nodes) {
      const { type, measured, data, id, position } = node;
      const { onDelete, isStartNode, status, ...restData } = data;
      if (type === "custom") {
        const newNode = {
          nodeId: id,
          ...restData,
        };
        transformedNodes.push(newNode);
      }
    }

    return transformedNodes;
  };

  const transformEdges = (edges) => {
    let transformedEdges = [];

    for (let edge of edges) {
      const { type, id, source, target, data } = edge;
      const { status } = data;

      const newEdge = {
        from: source,
        to: target,
        condition: `status=${status.toLowerCase()}`,
      };
      transformedEdges.push(newEdge);
    }

    return transformedEdges;
  };

  const onSave = () => {
    const nodes = getNodes();
    const edges = getEdges();

    const transformedNodes = transformNodes(nodes);
    const transformedEdges = transformEdges(edges);

    onSaveWorkflow({
      nodes: transformedNodes,
      edges: transformedEdges,
    });
  };

  return (
    <div className="z-10 w-full h-16 border-b p-4">
      <div className="flex justify-between items-center">
        <div className="flex flex-col">
          <p className="text-sm font-semibold ">
            {workflow?.name || "Workflow"}
          </p>
        </div>
        <div className="flex items-center gap-x-4">
          <div>
            <span className="text-gray-400 text-sm mr-2">Inactive</span>
            <Switch
              checked={isActive}
              onChange={() => {
                setIsActive((prevState) => {
                  onSaveWorkflow({ isActive: !prevState });
                  return !prevState;
                });
              }}
              name="test"
              color="green"
            />
            <span className="text-gray-400 text-sm ml-2">Active</span>
          </div>
          <Button onClick={onSave} outline disabled={!notify}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

export function Builder(props) {
  const { projectId } = useOutletContext();
  const [workflow, setWorkflow] = useState(null);
  const [notify, setNotify] = useState(false);
  const location = useLocation();
  const { id } = useParams();

  const fetchWorkflow = async () => {
    try {
      const response = await httpService.get(
        `/pipelines/getPipeline/${projectId}/${id}`
      );
      const data = response.data;
      setWorkflow(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSaveWorkflow = async (data) => {
    try {
      const response = await httpService.patch(
        `/pipelines/updatePipelines/${projectId}/${id}`,
        data
      );

      if (response.status === 200) {
        message.success("Saved");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (location.state?.workflow) {
      setWorkflow(location.state.workflow);
    } else if (projectId) {
      fetchWorkflow();
    }
  }, [projectId, location, id]);

  return (
    <ReactFlowProvider>
      <BuilderProvider
        value={{
          saveWorkflow: handleSaveWorkflow,
          notify,
          notifyChange: () => setNotify(true),
        }}
      >
        <div className="h-[calc(100vh-80px)] max-h-[calc(100vh-80px)]">
          <BuilderHeader
            workflow={workflow}
            onSaveWorkflow={handleSaveWorkflow}
          />

          {workflow && (
            <WorkflowBuilders
              onSave={handleSaveWorkflow}
              workflow={workflow}
              projectId={projectId}
            />
          )}
        </div>
      </BuilderProvider>
    </ReactFlowProvider>
  );
}
