import { PlusIcon } from "@heroicons/react/24/outline";
import { Handle, Position } from "@xyflow/react";

export function PlaceholderNode(props) {
  return (
    <>
      <div className="relative w-[300px] h-[80px] bg-white border-2 border-gray-300 border-dashed rounded-lg flex justify-between items-center p-4 shadow-md">
        <div className="flex mx-auto">
          <PlusIcon className="size-10 border border-dashed text-gray-300" />
        </div>
      </div>
      <Handle type="source" position={Position.Bottom} id="a" />
    </>
  );
}
