import { useState } from "react";
import { getthirdpartyData } from "V2.0/services/thirdpartyService";

function useCommunicationChannel({ workspaceId }) {
  const [communicationChannel, setCommunicationChannel] = useState(null);

  const fetchCommunicationChannels = async () => {
    try {
      const response = await getthirdpartyData(workspaceId);
      if (response.status === 200) {
        setCommunicationChannel(response.data);
      }
    } catch (e) {
      console.error("Error getting third party data");
    }
  };

  return {
    communicationChannel,
    fetchCommunicationChannels,
  };
}

export { useCommunicationChannel };
