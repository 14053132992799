import {
  Background,
  ReactFlow,
  useReactFlow,
  Handle,
  useNodesData,
  Position,
  getSmoothStepPath,
  BaseEdge,
  EdgeLabelRenderer,
} from "@xyflow/react";
import { Badge } from "V2.0/common/badge";

import useLayout from "./useLayout";
import { useState } from "react";
import Drawer from "V2.0/components/Drawer";
import { XMarkIcon } from "@heroicons/react/24/outline";

const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  return new Intl.DateTimeFormat("default", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short",
  }).format(date);
};

function generateWorkflowRunNodesAndEdges(nodes, workflowRun) {
  const nodeExecutions = workflowRun.nodeExecutions;
  const nodesMap = nodes.reduce((acc, node) => {
    return {
      ...acc,
      [node.nodeId]: node,
    };
  }, {});

  let edges = [];
  for (let i = 0; i < nodeExecutions.length - 1; ++i) {
    const sourceNode = nodeExecutions[i];
    const targetNode = nodeExecutions[i + 1];

    edges.push({
      id: `${sourceNode.nodeId}->${targetNode.nodeId}`,
      source: sourceNode.nodeId,
      target: targetNode.nodeId,
      data: {
        status: sourceNode.status,
      },
      type: "custom",
    });
  }

  let workflowRunNodes = [];
  for (let i = 0; i < nodeExecutions.length; ++i) {
    const executedNode = nodeExecutions[i];
    const node = nodesMap[executedNode.nodeId];
    workflowRunNodes.push({
      id: node.nodeId,
      ...node,
      ...executedNode,
      position: { x: 0, y: 200 * (1 + i) },
      type: "custom",
    });
  }

  return { workflowRunNodes, workflowRunEdges: edges };
}

const CustomNode = ({ id }) => {
  const internalNode = useNodesData(id);
  const { getNode } = useReactFlow();
  const node = getNode(id);
  const { settings } = node;

  return (
    <>
      <Handle type="target" position={Position.Top} />
      <div
        className={`cursor-pointer w-[300px] h-[80px] bg-white p-4 rounded-lg shadow-md border
              border-2 "border-blue-500"
             font-sans`}
      >
        <div className="flex justify-between items-center">
          <strong>{settings?.name}</strong>
        </div>
        {settings?.description}
      </div>
      <Handle type="source" position={Position.Bottom} />
    </>
  );
};

export function SimpleEdge(props) {
  const {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
  } = props;
  const { getEdge } = useReactFlow();
  const edge = getEdge(id);
  const data = edge.data;

  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge path={edgePath} />
      <EdgeLabelRenderer>
        <div className="edge-label-renderer__custom-edge nodrag nopan">
          <div
            className={`absolute pointer-events-auto `}
            style={{
              transform: `translate(-50%, -100%) translate(${labelX}px,${
                labelY + 8
              }px)`,
            }}
          >
            <Badge color={data.status === "completed" ? "green" : "red"}>
              {data.status}
            </Badge>
          </div>
        </div>
      </EdgeLabelRenderer>
    </>
  );
}

const nodeTypes = {
  custom: CustomNode,
};

const edgeTypes = {
  custom: SimpleEdge,
};

export function RunPreviewer(props) {
  const { workflow, workflowRun } = props;

  const { nodes, edges } = workflow;
  const { workflowRunNodes, workflowRunEdges } =
    generateWorkflowRunNodesAndEdges(nodes, workflowRun);
  const [open, setOpen] = useState({
    isOpen: false,
  });

  return (
    <div className="w-full h-full flex">
      <ReactFlow
        nodes={workflowRunNodes}
        edges={workflowRunEdges}
        width={600}
        height={600}
        minZoom={0.5}
        fitView
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onNodeClick={(e, node) => {
          setOpen({
            isOpen: true,
            node,
          });
        }}
        proOptions={{ hideAttribution: true }}
      >
        <Background />
      </ReactFlow>

      {open.isOpen && (
        <aside className="w-2/3 h-[100%] bg-white border-l">
          <div className="flex flex-col m-4 gap-y-8">
            <div className="flex justify-between gap-x-4 items-center min-h-16">
              <p className="font-semibold text-gray-700">Details</p>
              <div onClick={() => setOpen({ isOpen: false })}>
                <XMarkIcon className="size-6" />
              </div>
            </div>

            <div className="max-w-sm flex flex-col gap-y-4">
              <div className="flex flex-col gap-y-2 justify-between text-sm">
                <span className="text-gray-500">Name</span>
                <span className="text-gray-400">{open.node.settings.name}</span>
              </div>
            </div>

            <div className="max-w-sm flex flex-col gap-y-4">
              <div className="flex flex-col gap-y-2 justify-between text-sm">
                <span className="text-gray-500">Execution Start time</span>
                <span className="text-gray-400">
                  {formatDate(open.node.startTime)}
                </span>
              </div>
              <div className="flex flex-col gap-y-2 justify-between text-sm">
                <span className="text-gray-500">Execution End time</span>
                <span className="text-gray-400">
                  {formatDate(open.node.endTime)}
                </span>
              </div>
            </div>
          </div>
        </aside>
      )}
    </div>
  );
}
