import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { useWhatsappConfiguration } from "./models/useWhatsappConfiguration";
import { Button } from "V2.0/common/button";
import WhatsappIcon from "images/whatsapp.svg";

import { WhatsappForm } from "./components/Whatsapp_form";
import { WhatsappManager } from "./components/Whatsapp_manager";
import { IntegrationShell } from "../common/Shell";

import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";

import { deleteWhatsAppConfig } from "V2.0/services/whatsappService";
import { WhatsAppSettings } from "./components/Whatsapp_manager/Whatsapp_settings";

function WhatsappShell({ projectId, fetchWhatsappConfiguration, settings, fetchSettings, children }) {
  const navigate = useNavigate();

  const handleDisconnect = async () => {
    try {
      const response = await deleteWhatsAppConfig(projectId);
      if (response.status === 200) {
        fetchWhatsappConfiguration(projectId);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className=" m-8 w-4/5 mx-auto">
      <header>
        <Button plain onClick={() => navigate("/integrations")}>
          <ArrowLeftIcon />
          back
        </Button>
        <div className=" flex justify-between px-8 pt-8 py-4">
          <div className="flex gap-x-2 items-center">
            <img src={WhatsappIcon} className="size-6" />
            <h3 className="text-xl font-semibold">Whatsapp</h3>
          </div>
          <div className="flex items-center gap-x-2">
            <WhatsAppSettings
              projectId={projectId}
              settings={settings}
              fetchSettings={fetchSettings}
            />
            <Button onClick={handleDisconnect}>Disconnect</Button>
          </div>
        </div>
      </header>
      <div>{children}</div>
    </div>
  );
}

function Whatsapp() {
  const { activeProject, projects, settings, fetchSettings, projectId } =
    useOutletContext();
  const { whatsappConfiguration, fetchWhatsappConfiguration } =
    useWhatsappConfiguration(projects[activeProject]?._id);

  // if (whatsappConfiguration.loading && !whatsappConfiguration.isConfigured) {
  //   return <p>Loading</p>;
  // }

  if (whatsappConfiguration.isConfigured) {
    return (
      <WhatsappShell
        fetchWhatsappConfiguration={fetchWhatsappConfiguration}
        projectId={projectId}
        settings={settings}
        fetchSettings={fetchSettings}
      >
        <WhatsappManager
          existingConfiguration={whatsappConfiguration.data}
          projectId={projects[activeProject]?._id}
          fetchWhatsappConfiguration={fetchWhatsappConfiguration}
        />
      </WhatsappShell>
    );
  }

  if (!whatsappConfiguration.isConfigured && !whatsappConfiguration.loading) {
    return (
      <IntegrationShell>
        <WhatsappForm projectId={projects[activeProject]?._id} fetchWhatsappConfiguration={fetchWhatsappConfiguration}/>
      </IntegrationShell>
    );
  }
}

export default Whatsapp;
