import {
  EdgeLabelRenderer,
  BaseEdge,
  useReactFlow,
  getSmoothStepPath,
} from "@xyflow/react";
import { Badge } from "V2.0/common/badge";
import { useWorkflowBuilder } from "../index1";

export function SimpleEdge(props) {
  const {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    data,
  } = props;
  const { getEdge } = useReactFlow();
  const edge = getEdge(id);
  const { openPanel } = useWorkflowBuilder();

  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge path={edgePath} />
      <EdgeLabelRenderer>
        <div className="edge-label-renderer__custom-edge nodrag nopan">
          <div
            className={`absolute pointer-events-auto `}
            style={{
              transform: `translate(-50%, -100%) translate(${labelX}px,${
                labelY + 8
              }px)`,
            }}
          >
            <Badge color={data.status === "Completed" ? "green" : "red"}>
              {data.status}
            </Badge>
          </div>
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
