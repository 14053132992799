import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
  TableCell,
} from "V2.0/common/table";
import httpService from "V2.0/services/httpService";
import { formatTimestamp } from "../Threads/components/conversation_messages";
import {
  Dialog,
  DialogTitle,
  DialogBody,
  DialogActions,
} from "V2.0/common/dialog";
import { RunPreviewer } from "./Builder/RunPreview";
import { ReactFlowProvider } from "@xyflow/react";
import { Button } from "V2.0/common/button";

export function WorkflowRuns(props) {
  const { workflow, projectId } = props;
  const [workflowRuns, setWorkflowRuns] = useState([]);
  const [open, setOpen] = useState({
    isOpen: false,
    workflowRun: null,
  });

  const fetchWorkflowRuns = async () => {
    try {
      const response = await httpService.get(
        `/pipelineRuns/getPipelineRuns/${projectId}`
      );
      const { pipelineRuns } = response.data;
      setWorkflowRuns(pipelineRuns);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (projectId) {
      fetchWorkflowRuns();
    }
  }, [projectId, workflow]);

  if (workflowRuns) {
    return (
      <>
        <Table>
          <TableHead>
            <TableHeader>Contact</TableHeader>
            <TableHeader>Status</TableHeader>
            <TableHeader>Started At</TableHeader>
          </TableHead>
          <TableBody>
            {workflowRuns.map((run, idx) => {
              const { contact, status, startTime } = run;
              return (
                <TableRow
                  onClick={() => setOpen({ isOpen: true, workflowRun: run })}
                >
                  <TableCell>{contact}</TableCell>
                  <TableCell>{status}</TableCell>
                  <TableCell>{formatTimestamp(startTime)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {/* 
        {workflow && workflowRuns.length > 0 && (
          <div className="w-full h-full">
            <ReactFlowProvider>
              <RunPreviewer workflow={workflow} workflowRun={workflowRuns[0]} />
            </ReactFlowProvider>
          </div>
        )} */}

        {open.isOpen && (
          <Dialog
            size="5xl"
            open={open.isOpen}
            onClose={() => setOpen({ isOpen: false, workflowRun: null })}
          >
            <DialogTitle>Workflow Run</DialogTitle>
            <DialogBody>
              <div className="w-[100%] h-[80vh] relative border">
                <ReactFlowProvider>
                  <RunPreviewer
                    workflow={workflow}
                    workflowRun={open.workflowRun}
                  />
                </ReactFlowProvider>
              </div>
            </DialogBody>
            <DialogActions>
              <Button outline onClick={() => setOpen({ isOpen: false })}>Close</Button>
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  }
}
