import { useState } from "react";

import { WebhookCreate } from "./WebhookCreate";
import { WebhookDelete } from "./WebhookDelete";
import { WebhooksTable } from "./WebhooksTable";

import * as webhooksService from "./service";

function Webhooks(props) {
  const { assistantId, webhooks, workspaceId, syncAssistant } = props;
  const { webhookList } = webhooks;
  const [deleteWebhook, setDeleteWebhook] = useState({
    isOpen: false,
    data: null,
  });

  const addWebhook = async (data) => {
    try {
      const response = await webhooksService.postWebhook({
        workspaceId,
        assistantId,
        payload: data,
      });
      if (response.status === 200) {
        syncAssistant((prevAssistant) => {
          return {
            ...prevAssistant,
            webhooks: response.data,
          };
        });
        console.log("Success");
      }
    } catch (e) {
      console.error("Error adding webhook");
    }
  };

  const confirmDeleteWebhook = async () => {
    const { item, index } = deleteWebhook.data;
    try {
      const response = await webhooksService.deleteWebhook({
        workspaceId,
        assistantId,
        webhookId: item._id,
      });
      if (response.status === 200) {
        console.log("Success");
        syncAssistant((prevAssistant) => {
          return {
            ...prevAssistant,
            webhooks: response.data,
          };
        });
      }
    } catch (e) {
      console.error("Error deleting webhook");
    }
  };

  return (
    <>
      <div className="p-4">
        <div className="flex justify-between">
          <p>Webhooks</p>
          <WebhookCreate
            onSubmit={(data) => {
              addWebhook(data);
            }}
          />
        </div>
        <div>
          <WebhooksTable
            webhooks={webhookList}
            onWebhookDelete={(item, index) => {
              setDeleteWebhook({ isOpen: true, data: { item, index } });
            }}
          />
        </div>
      </div>
      <WebhookDelete
        webhook={deleteWebhook.data}
        open={deleteWebhook.isOpen}
        onClose={() => setDeleteWebhook({ isOpen: false, data: null })}
        onWebhookDelete={confirmDeleteWebhook}
      />
    </>
  );
}

export { Webhooks };
