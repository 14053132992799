import {
  EdgeLabelRenderer,
  BaseEdge,
  useReactFlow,
  getSmoothStepPath,
  getSimpleBezierPath,
} from "@xyflow/react";
import { Badge } from "V2.0/common/badge";
import { useEffect } from "react";
import { useWorkflowBuilder } from "../index1";

export function CustomEdge(props) {
  const {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    data,
  } = props;
  const { getEdge, getEdges } = useReactFlow();
  const edge = getEdge(id);
  const { openPanel } = useWorkflowBuilder();

  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const onClick = () => {
    openPanel({
      mode: "add",
      data: { edge, status: "Completed" },
    });
  };

  const btn = (
    <button
      onClick={onClick}
      className="bg-white w-[30px] h-[30px] border border-1 cursor-pointer rounded-lg hover:border-gray-700"
    >
      +
    </button>
  );

  return (
    <>
      <BaseEdge path={edgePath} />
      <EdgeLabelRenderer>
        <div className="nodrag nopan">
          <div
            className={`absolute pointer-events-auto `}
            style={{
              transform: `translate(-50%, -100%) translate(${labelX}px,${
                labelY + 12
              }px)`,
            }}
          >
            <Badge color={data?.status === "completed" ? "green" : "red"}>
              {data?.status}
            </Badge>
          </div>
        </div>
        {/* <div
          className={`absolute pointer-events-auto`}
          style={{
            transform: `translate(-50%,-200%) translate(${labelX}px, 100px)`,
          }}
        >
          {btn}
        </div> */}
      </EdgeLabelRenderer>
    </>
  );
}
