import { useState, useEffect, useMemo } from "react";
import { TableComponent } from "V2.0/HomePages/HomeModule/CustomAPIs/components/TableComponent";
import { Button } from "V2.0/common/button";
import { Field, Label, Description } from "V2.0/common/fieldset";
import { Checkbox, CheckboxField, CheckboxGroup } from "V2.0/common/checkbox";
import { getKnowledgebases } from "V2.0/HomePages/Knowledge_base/service";
import {
  knowledgeBaseMock1,
  knowledgeBasesMock,
} from "V2.0/HomePages/Knowledge_base/mock/mock";

const headers = [{ key: "label", label: "Knowledge base Name" }];

function KnowledgeBase(props) {
  const {
    ragSettings,
    syncAssistant,
    assistantId,
    workspaceId,
    assistantType,
  } = props;
  const { knowledgeBases } = ragSettings;
  const [openDelete, setOpenDelete] = useState({
    item: null,
    isOpen: false,
  });
  const [availableKnowledgeBases, setAvailableKnowledgeBases] = useState([]);
  const [selectedKnowledgeBases, setSelectedKnowledgeBases] = useState([]);

  useEffect(() => {
    setSelectedKnowledgeBases([knowledgeBaseMock1._id]);
    // setSelectedKnowledgeBases(knowledgeBases);
  }, [knowledgeBases]);

  const fetchKnowledgeBases = async () => {
    try {
      const response = await getKnowledgebases({ projectId: workspaceId });
      const data = response.data;
      setAvailableKnowledgeBases(data);
    } catch (e) {
      console.error("Error");
    }
  };

  useEffect(() => {
    if (workspaceId) {
      setAvailableKnowledgeBases(knowledgeBasesMock);
      // fetchKnowledgeBases();
    }
  }, [workspaceId]);

  const knowledgeBasesList = useMemo(() => {
    return availableKnowledgeBases.map((kb) => {
      return {
        id: kb._id,
        label: kb.label,
        isSelected: selectedKnowledgeBases.includes(kb._id),
      };
    });
  }, [availableKnowledgeBases, selectedKnowledgeBases]);

  return (
    <>
      <div className="flex flex-col w-1/3 mx-auto mt-4 p-4 border rounded-lg">
        {/* <div className="w-full flex items-center justify-between">
          <p>Knowledge Base</p>
          <Button onClick={() => {}}>Save</Button>
        </div> */}
        <Field>
          <Label>Available Knowledge Bases</Label>
          <Description>
            Select from the available list of knowledge bases
          </Description>
          <CheckboxGroup>
            {knowledgeBasesList.map((kb) => {
              let handleChange;

              const unselectKnowledgeBase = () => {
                const idx = selectedKnowledgeBases.findIndex(
                  (_kb) => _kb === kb.id
                );
                setSelectedKnowledgeBases((prevState) => {
                  return prevState.toSpliced(idx, 1);
                });
              };

              const selectKnowledgeBase = () => {
                setSelectedKnowledgeBases((prevState) => {
                  return [...prevState, kb.id];
                });
              };

              handleChange = kb.isSelected
                ? unselectKnowledgeBase
                : selectKnowledgeBase;

              return (
                <CheckboxField key={kb.id}>
                  <Checkbox
                    name={kb.id}
                    value={kb.isSelected}
                    onChange={handleChange}
                  />
                  <Label>{kb.label}</Label>
                </CheckboxField>
              );
            })}
          </CheckboxGroup>
        </Field>
        <div className="flex justify-end">
          <Button>Save</Button>
        </div>
      </div>
    </>
  );
}

export { KnowledgeBase };
