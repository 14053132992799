import React, { Fragment, useState, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Textarea } from "../common/textarea";
import { useLocation } from "react-router-dom";
import InputwithArrow from "./InputwithArrow";
import loadingIcon from "../images/animation.svg";

export default function DemoComponent({
  open,
  setOpen,
  SidebarTitle,
  projects,
  activeProject,
  assistantId
}) {
  const [loading, setLoading] = useState(true);

  const apiPoint = process.env.REACT_APP_API_POINT;

  const [drawerVisible, setDrawerVisible] = useState(false);
  const location = useLocation();
  const isCloseButtonVisible = location.pathname === "/";

  const [drawerWidth, setDrawerWidth] = useState(700);

  let apiURL = `${apiPoint}api/v1/embedChat`;

  const [conversationId, setConversationId] = useState("");
  const [conversation, setConversation] = useState([]);
  const [asked, setAsked] = useState("");
  const [streamingResponse, setStreamingResponse] = useState("");
  const [streamingActivity, setStreamingActivity] = useState(false);
  const [question, setQuestion] = useState("");

  const conversationContainerRef = useRef(null);

  useEffect(() => {
    const container = conversationContainerRef.current;
    if (container) {
      setTimeout(() => {
        container.scrollTop = container.scrollHeight;
      }, 100); // Small delay to ensure the DOM has updated
    }
  }, [conversation]);

  useEffect(() => {
    setConversationId("");
    setConversation([]);
  }, [activeProject]);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    }
  };

  const handleSubmit = async (latestQuestion) => {
    if (streamingActivity) return;

    let currentQuestion =
      typeof latestQuestion === "string" ? latestQuestion : question;

    setQuestion("");

    if (asked === "true") return;

    if (currentQuestion === "") return;

    let newElement = {};
    setAsked("true");
    newElement.question = currentQuestion;

    let array = [...conversation, newElement];
    setConversation(array);
    setStreamingResponse("");

    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        //ProjectID change is there compared to supfrontend
        body: JSON.stringify({
          workspaceId: projects[activeProject]._id,
          assistantId,
          question: currentQuestion,
          ...(conversationId !== "" && { conversationId: conversationId }),
        }),
      });

      const reader = response.body.getReader();
      // console.log("reader", reader);

      const processResponse = async () => {
        let result = "";
        let isFirstChunk = true;
        let conversationId = null;

        while (true) {
          const { done, value } = await reader.read();

          let data = new TextDecoder().decode(value);

          if (done) {
            return;
          }

          setAsked("false");
          setStreamingActivity(true);

          if (!data.includes("conversationId:")) {
            result += data;
          }

          setStreamingResponse(result);
          const newArray = [...array];

          const lastIndex = newArray.length - 1;
          const lastItem = newArray[lastIndex];
          lastItem.answer = result;
          setConversation(newArray);

          if (data.includes("conversationId:")) {
            const parts = data.split("conversationId:");
            const lastElement = parts[parts.length - 1];
            const strippedStr = lastElement.trim();

            const firstElement = parts[0];

            if (firstElement) {
              result += firstElement;

              setStreamingResponse(result);
              const newArray = [...array];

              const lastIndex = newArray.length - 1;
              const lastItem = newArray[lastIndex];
              lastItem.answer = result;
              setConversation(newArray);
            }

            setConversationId(strippedStr);
            setStreamingActivity(false);
            setAsked("false");
          }

          //const dataElements = data.split(/\n/);

          // if (data.includes("data:")){

          //   //data.replace(/\s+$/, "");
          //   if (!data.includes("\n\n")) {
          //      data = data.trim() }

          // console.log("data inside 1st condition", data)
          //const dataElements = data.split(/\n/);
          // console.log("data elements", dataElements)
          // for (const dataElement of dataElements) {
          //   let element = dataElement.trim()
          // console.log("1 element", element)

          // if (data && data.includes("data:")){
          //   setAsked("false");
          //   setStreamingActivity(true)
          //   const parts = data.split('data:');
          // //console.log("parts", parts);
          //   const text = parts[parts.length - 1];

          //   result+=text;
          //   setStreamingResponse(result);
          //   const newArray =  [...array];

          //   const lastIndex = newArray.length-1;
          //   const lastItem =  newArray[lastIndex];
          //   lastItem.answer = result;
          //   setConversation(newArray);

          // console.log("executed")
          //}
          //}
          //}
        }
      };
      processResponse();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = (e) => {
    setQuestion(e.target.value);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            {SidebarTitle}
                          </Dialog.Title>
                          <div className="ml-3 h-7 flex items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                              onClick={() => setOpen(false)}
                            >
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        <div
                          ref={conversationContainerRef}
                          className="flex-1 flex-grow overflow-y-auto py-6 space-y-4 max-h-192"
                        >
                          {conversation.length >= 1 &&
                            conversation.map((item, index) => (
                              <React.Fragment key={index}>
                                <div className="flex justify-start">
                                  <div className="max-w-sm md:max-w-md rounded-lg bg-blue-100 p-4 text-gray-700">
                                    <p className="break-words">
                                      {item.question}
                                    </p>
                                  </div>
                                </div>
                                <div className="flex justify-end">
                                  <div className="max-w-sm md:max-w-md rounded-lg bg-blue-600 p-4 text-white">
                                    <p className="break-words">
                                      {conversation.length === index + 1 &&
                                      asked === "true" ? (
                                        <img
                                          className="w-32 h-7"
                                          src={loadingIcon}
                                          alt="Loading..."
                                        />
                                      ) : (
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: item.answer,
                                          }}
                                        />
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </React.Fragment>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div className="px-4 py-4">
                      <InputwithArrow
                        value={question}
                        asked={asked}
                        handleSubmit={handleSubmit}
                        onChange={handleChange}
                        handleKeyDown={handleKeyDown}
                        streamingActivity={streamingActivity}
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
