import http from "V2.0/services/httpService";

const base = "/assistants/webhooks";

function getWebhooks({ assistantId, workspaceId }) {
  return http.get(`${base}/getWebhooks/${workspaceId}/${assistantId}`);
}

function postWebhook({ assistantId, workspaceId, payload }) {
  return http.post(`${base}/subscribe/${workspaceId}/${assistantId}`, payload);
}

function deleteWebhook({ assistantId, workspaceId, webhookId }) {
  return http.delete(
    `${base}/unsubscribe/${workspaceId}/${assistantId}/${webhookId}`
  );
}

export { getWebhooks, postWebhook, deleteWebhook };
