import { useState, useEffect } from "react";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import httpService from "V2.0/services/httpService";
import { WorkflowRuns } from "./WorkflowRuns";
import { Button } from "V2.0/common/button";

export function Workflow(props) {
  const { projectId } = useOutletContext();
  const [workflow, setWorkflow] = useState(null);

  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();

  const fetchWorkflow = async () => {
    try {
      const response = await httpService.get(
        `/pipelines/getPipeline/${projectId}/${id}`
      );
      const data = response.data;
      setWorkflow(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (location.state?.workflow) {
      setWorkflow(location.state.workflow);
    } else if (projectId) {
      fetchWorkflow();
    }
  }, [projectId, location]);

  if (workflow) {
    return (
      <div>
        <div className="flex justify-between p-4">
          <p className="text-sm">{workflow.name}</p>
          <Button
            onClick={() =>
              navigate(`/pipelines/${workflow._id}/edit`, { state: workflow })
            }
          >
            Edit Workflow
          </Button>
        </div>
        <div>
          <WorkflowRuns workflow={workflow} projectId={projectId} />
        </div>
      </div>
    );
  }
}
