import {
  ReactFlow,
  useNodesState,
  useEdgesState,
  Background,
  Controls,
  Handle,
  Position,
  getIncomers,
  getOutgoers,
  getConnectedEdges,
  BaseEdge,
  EdgeLabelRenderer,
  getSmoothStepPath,
  useInternalNode,
  useNodesData,
  useReactFlow,
} from "@xyflow/react";
import { useState, useEffect, createContext, useContext, useMemo } from "react";
import {
  PlusIcon,
  PencilIcon,
  TrashIcon,
  PhoneIcon,
  ChatBubbleLeftIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";
import Drawer from "V2.0/components/Drawer";
import { Badge, BadgeButton } from "V2.0/common/badge";
import { FaWhatsapp } from "react-icons/fa";
import { NODE_SETTINGS } from "./nodesSettings";
import { nanoid } from "nanoid";
import { useBuilder } from "../Builder";
import useLayout from "./useLayout";
import useAutoLayout from "./useAutoLayout";
import { StartEdge, CustomEdge } from "./edges";
import { StartNode, EndNode, CustomNode } from "./nodes/index1";

const nodeTypes = {
  custom: (node) => (
    <CustomNode
      {...node}
      onDelete={node.data.onDelete}
      selected={node.selected}
    />
  ),
  start: (node) => <StartNode {...node} onAdd={node.data.onAdd} />,
  end: (node) => <EndNode {...node} />,
};

const edgeTypes = {
  customEdge: (edge) => <CustomEdge {...edge} onClick={edge.data.onClick} />,
  startEdge: (edge) => <StartEdge {...edge} onClick={edge.data.onClick} />,
};

const initialNodes = [
  {
    id: "start",
    type: "start",
    position: { x: 0, y: 0 },
    data: {
      title: "Start",
    },
  },
  {
    id: "end",
    type: "end",
    position: { x: 0, y: 0 },
    data: { title: "End" },
  },
];

const initialEdges = [
  {
    id: "start->end",
    source: "start",
    target: "end",
    type: "startEdge",
  },
];

function generateNodesAndEdges(workflow) {
  const { nodes, edges } = workflow || [];

  const [startNode, endNode] = initialNodes;
  const [startEndEdge] = initialEdges;

  if (nodes.length === 0) {
    return {
      initialNodes,
      initialEdges,
    };
  } else {
    const transformedNodes = nodes.map((node, idx) => {
      const { nodeId, settings, communicationType, nodeType } = node;
      return {
        id: node.nodeId,
        data: {
          settings: {
            ...settings,
          },
          nodeType,
          ...(communicationType ? { communicationType } : {}),
        },
        type: "custom",
        position: { x: 0, y: 0 },
      };
    });

    const firstNode = nodes[0];
    const lastNode = nodes[nodes.length - 1];

    const startFirstNodeEdge = {
      id: `start->${firstNode.nodeId}`,
      source: "start",
      target: firstNode.nodeId,
      type: "customEdge",
    };
    const lastNodeEndEdge = {
      id: `${lastNode.nodeId}->end`,
      source: lastNode.nodeId,
      target: "end",
      type: "customEdge",
    };
    const transformedEdges = edges.map((edge) => {
      const { from, to } = edge;
      return {
        id: `${from}->${to}`,
        source: from,
        target: to,
        type: "customEdge",
      };
    });

    return {
      initialNodes: [startNode, ...transformedNodes, endNode],
      initialEdges: [startFirstNodeEdge, ...transformedEdges, lastNodeEndEdge],
    };
  }
}

const WorkflowBuilderContext = createContext({});
export const useWorkflowBuilder = () => {
  return useContext(WorkflowBuilderContext);
};

export function WorkflowBuilders(props) {
  const { workflow } = props;
  const { initialNodes, initialEdges } = generateNodesAndEdges(workflow);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [panel, setPanel] = useState({ isOpen: false });
  const { notifyChange } = useBuilder();
  const { getNode } = useReactFlow();

  const openPanel = (data) => setPanel({ isOpen: true, ...data });
  const closePanel = () => setPanel({ isOpen: false });

  useLayout();
  // useAutoLayout({
  //   direction: "TB"
  // });

  const handleAddPath = (status) => {
    notifyChange();

    /**
     isPathAlreadyExists = find_edges(source) is greater than two
     if isPathAlreadyExists
      create a dummy node
      add an edge between the source node and the newly created dummy node
      add a new custom edge with status to the dummy node of that path or sub tree

    if !isPathAlreadyExists
      create a dummy node
      add an edge between the source node and the newly created dummy node
      add an edge from the created dummy node to the target node.
      
     */
    setPanel((prevData) => ({
      isOpen: true,
      mode: "add",
      data: {
        ...prevData.data,
        status
      }
    }))

  };

  const handleAddNode = (node) => {
    notifyChange();
    const data = panel.data;
    const sourceNode = getNode(panel.data.edge.source);
    const targetNode = getNode(panel.data.edge.target);
    const parentId = panel.data.parentId;
    const status = panel.data.status;

    const newNode = {
      ...node,
      id: nanoid(),
      position: {
        x: targetNode.position.x,
        y: targetNode.position.y,
      },
    };

    const newEdges = [
      {
        id: `${newNode.id}->${targetNode.id}`,
        source: newNode.id,
        target: targetNode.id,
        type: "customEdge",
        data: {
          status: status ? status : "Completed",
        },
      },
      {
        id: `${sourceNode.id}->${newNode.id}`,
        source: sourceNode.id,
        target: newNode.id,
        type: sourceNode.id === "start" ? "startEdge" : "customEdge",
        ...(sourceNode.id === "start"
          ? {}
          : {
              data: {
                status: "Completed",
              },
            }),
      },
    ];

    console.log({ newEdges });

    setEdges((edges) =>
      edges
        .filter((edge) => edge.id != `${sourceNode.id}->${targetNode.id}`)
        .concat(newEdges)
    );

    setNodes((nodes) => {
      return nodes
        .map((node) => {
          const { id } = node;
          if (id === "end" && newNode.position.y === node.position.y) {
            return {
              ...node,
              position: { x: 0, y: node.position.y + 200 },
            };
          }

          return node;
        })
        .concat(newNode);
    });

    setPanel({
      isOpen: true,
      mode: "Configure",
      data: newNode,
    });
  };

  const handleDeleteNode = (deletedNode) => {
    const updatedNodes = nodes.filter((node) => node.id != deletedNode.id); // removing the deleted node

    const incomers = getIncomers(deletedNode, nodes, edges); // incomers are the nodes which connect to the deleted node
    const outgoers = getOutgoers(deletedNode, nodes, edges); // outgoers are the nodes which can be reached from the deleted node
    const connectedEdges = getConnectedEdges([deletedNode], edges); // there will be an edge in which the deleted node is a target and another where
    // this node is a source.

    console.log({ incomers, outgoers, connectedEdges });

    const remainingEdges = edges.filter(
      (edge) => !connectedEdges.includes(edge)
    ); // remove all the edges which includes the deleted node in its source/target

    const createdEdges = incomers.flatMap(({ id: source }) => {
      return outgoers.map(({ id: target }) => {
        return {
          id: `${source}->${target}`,
          source,
          target,
          type: source === "start" ? "startEdge" : "customEdge",
        };
      });
    });

    const updatedEdges = [...remainingEdges, ...createdEdges];
    setNodes(updatedNodes);
    setEdges(updatedEdges);
  };

  const updatedNodes = useMemo(
    () =>
      nodes.map((node) => {
        return {
          ...node,
          data: {
            ...node.data,
            onDelete: () => {
              console.log("delete");
              handleDeleteNode(node);
            },
          },
        };
      }),
    [nodes]
  );

  const updatedEdges = useMemo(
    () =>
      edges.map((edge) => {
        return {
          ...edge,
          data: {
            ...edge.data,
          },
        };
      }),
    [edges]
  );

  const handleNodeClick = (e, node) => {
    openPanel({
      mode: "Configure",
      data: node,
    });
  };

  const handleEdgeClick = (e, edge) => {
    openPanel({
      mode: "add",
      data: edge,
    });
  };

  return (
    <WorkflowBuilderContext.Provider
      value={{
        openPanel,
      }}
    >
      <ReactFlow
        nodes={updatedNodes}
        edges={updatedEdges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onNodeClick={handleNodeClick}
        // onEdgeClick={handleEdgeClick}
        nodesDraggable={false}
        fitView
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        minZoom={0.5}
        zoomOnScroll
        panOnScroll
      >
        <Background />
        <Controls />
      </ReactFlow>
      {panel.isOpen && (
        <Drawer open={panel.isOpen} onClose={closePanel} title="Configuration">
          {panel.mode === "add" && (
            <div className="flex flex-col gap-y-4">
              <div className="flex flex-col gap-y-2">
                <p className="text-sm font-semibold text-gray-500">Action</p>
                <div className="flex flex-col gap-y-2">
                  <div
                    onClick={() =>
                      handleAddNode({
                        data: {
                          nodeType: "communication",
                          communicationType: "call",
                          settings: {
                            name: "Call",
                            description: "",
                            message: "",
                          },
                        },
                        selected: true,
                        type: "custom",
                      })
                    }
                    className="flex items-center border rounded px-2 py-1 gap-x-2 cursor-pointer hover:bg-gray-200"
                  >
                    <PhoneIcon className="size-4" /> Call
                  </div>
                  <div
                    onClick={() =>
                      handleAddNode({
                        data: {
                          nodeType: "communication",
                          communicationType: "sms",
                          settings: {
                            name: "Sms",
                            description: "",
                            message: "",
                          },
                        },
                        selected: true,
                        type: "custom",
                      })
                    }
                    className="flex items-center border rounded px-2 py-1 gap-x-2 cursor-pointer hover:bg-gray-200"
                  >
                    <ChatBubbleLeftIcon className="size-4" /> SMS
                  </div>
                  <div
                    onClick={() =>
                      handleAddNode({
                        data: {
                          nodeType: "communication",
                          communicationType: "whatsapp",
                          settings: {
                            name: "Whatsapp",
                            description: "",
                            template: "",
                          },
                        },
                        selected: true,
                        type: "custom",
                      })
                    }
                    className="flex items-center border rounded px-2 py-1 gap-x-2 cursor-pointer hover:bg-gray-200"
                  >
                    <FaWhatsapp className="size-4" /> WhatsApp
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-y-2">
                <p className="text-sm font-semibold text-gray-500">Timers</p>
                <div className="flex flex-col gap-y-2">
                  <div
                    onClick={() =>
                      handleAddNode({
                        data: {
                          nodeType: "delay",
                          settings: {
                            name: "Delay",
                            description: "",
                            delaySeconds: "",
                          },
                        },
                        selected: true,
                        type: "custom",
                      })
                    }
                    className="flex items-center border rounded px-2 py-1 gap-x-2 cursor-pointer hover:bg-gray-200"
                  >
                    <ClockIcon className="size-4" /> Delay
                  </div>
                </div>
              </div>
            </div>
          )}
          {panel.mode === "addPath" && (
            <div className="flex flex-col gap-y-4">
              <div className="flex flex-col gap-y-2">
                <p className="text-sm font-semibold text-gray-500">
                  Select Condition
                </p>
                <div className="flex gap-x-4">
                  <BadgeButton
                    color="green"
                    onClick={() => {
                      handleAddPath("Completed");
                    }}
                  >
                    Completed
                  </BadgeButton>
                  <BadgeButton
                    color="red"
                    onClick={() => {
                      handleAddPath("Failed");
                    }}
                  >
                    Failed
                  </BadgeButton>
                </div>
              </div>
            </div>
          )}
          {panel.mode === "Configure" && (
            <div className="flex flex-col">
              {NODE_SETTINGS[panel.data.data.nodeType]({
                communicationType: panel.data.data.communicationType,
                node: panel.data,
                setNodes,
                closePanel,
              })}
            </div>
          )}
        </Drawer>
      )}
    </WorkflowBuilderContext.Provider>
  );
}
