import http from "./httpService";
const apiEndpoint = "/voice";

function placeVoiceCall({ workspaceId, assistantId, payload }) {
  return http.post(
    `${apiEndpoint}/placeOutboundCall/${workspaceId}/${assistantId}`,
    payload
  );
}

export function placeCall(projectId, req) {
  return http.post(
    apiEndpoint + "/" + "placeoutboundcall" + "/" + projectId,
    req
  );
}

export function registerTwilioNumber(projectId, req) {
  return http.post(apiEndpoint + "/" + "registerNumber" + "/" + projectId, req);
}

export { placeVoiceCall };
