import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Joi from "joi-browser";
import * as whatsappService from "V2.0/services/whatsappService";

import { Button } from "V2.0/common/button";
import Form from "V2.0/components/Form";
import CopyInput from "V2.0/common/CopyInput";

import { message } from "antd";

import { ArrowLeftIcon } from "@heroicons/react/24/outline";

import WhatsappIcon from "images/whatsapp.svg";

export function WhatsappForm(props) {
  const { projectId, fetchWhatsappConfiguration } = props;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [formFieldforWhatsapp] = useState({
    wabaID: "",
    wabaAccessToken: "",
    phoneNumberId: "",
  });

  const [errorMessageWhatsapp] = useState({});

  const templateWhatsapp = [
    {
      name: "wabaID",
      label: "Whatsapp Business Account ID",
      type: "text",
      style: "",
    },
    {
      name: "wabaAccessToken",
      label: "Access token",
      type: "text",
      style: "",
    },
    {
      name: "phoneNumberId",
      label: "Phone Number ID",
      type: "text",
    },
  ];

  const buttonInfoWhatsapp = {
    label: "Connect",
    style: "entire-width",
  };

  const schemaWhatsapp = {
    wabaID: Joi.string()
      .max(25)
      .required()
      .label("WhatsApp Business Account ID"),
    wabaAccessToken: Joi.string().max(300).required().label("Access Token"),
    phoneNumberId: Joi.string().max(50).required().label("Phone Number ID"),
  };

  const handleWhatsapp = async (data) => {
    const updatedObj = {};

    for (let key in data) {
      if (typeof data[key] === "string") {
        updatedObj[key] = data[key].trim();
      } else {
        updatedObj[key] = data[key];
      }
    }

    const { phoneNumberId } = updatedObj;
    const payload = {
      ...updatedObj,
      phoneNumberIds: [phoneNumberId],
    };

    try {
      const response = await whatsappService.manageWhatsapp(projectId, payload);

      if (response.status === 200) {
        message.success("Whatsapp configuration saved");
        fetchWhatsappConfiguration(projectId);
        setLoading(false);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        setLoading(false);
        message.error(ex.response.data.msg);
        return;
      }
    }
  };

  return (
    <div>
      <div className="flex flex-col items-center">
        <div className="w-full max-w-md p-8 border rounded-lg">
          <div className="flex gap-x-2 mb-4">
            <img src={WhatsappIcon} className="size-6" />
            <h3 className="text-lg">Whatsapp Business Account</h3>
          </div>
          <div>
            <div className="my-5">
              <CopyInput
                value="https://api.superdashhq.com/api/whatsapp/webhook"
                title="Whatsapp Webhook URL"
              />
            </div>

            <div className="my-5">
              <CopyInput
                value="4915359cede4b64cb190f02bc1b396af"
                title="Verify token"
              />
            </div>
            <div>
              <Form
                key={JSON.stringify(formFieldforWhatsapp)}
                errorMessage={errorMessageWhatsapp}
                inputField={formFieldforWhatsapp}
                template={templateWhatsapp}
                schema={schemaWhatsapp}
                buttonInfo={buttonInfoWhatsapp}
                onSubmit={handleWhatsapp}
                loading={loading}
                setLoading={setLoading}
              />
            </div>
          </div>

          {/* New instructions at the bottom */}
          <div className="mt-6 text-sm text-gray-500">
            <p>
              Please find instructions to integrate Whatsapp{" "}
              <a
                href="https://superdashhq.notion.site/Super_-WhatsApp-Integration-Instructions-17933f1b92d44e5588ad510dc2885995"
                className="text-blue-500 underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
