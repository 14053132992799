import { useState, useEffect } from "react";
import { useCommunicationChannel } from "./useCommunicationChannel";
import { useCommunicationBinding } from "./useCommunicationBinding";
import { PhoneNumberBinding } from "./PhoneNumberBinding/PhoneNumberBinding";

function VoiceCommunicationBinding(props) {
  const {
    communicationBinding,
    syncAssistant,
    assistantId,
    workspaceId,
    assistantType,
  } = props;

  const { addCommunicationBinding, removeCommunicationBinding } =
    useCommunicationBinding({ syncAssistant, assistantId, workspaceId });

  const { communicationChannel, fetchCommunicationChannels } =
    useCommunicationChannel({ workspaceId });

  const { phoneNumbers } = communicationChannel?.telephonySettings
    ?.twilioSettings.phoneNumbers || { phoneNumbers: [] };

  const [openDelete, setOpenDelete] = useState({
    item: null,
    isOpen: false,
  });

  useEffect(() => {
    fetchCommunicationChannels();
  }, []);

  const handleBindingDelete = () => {
    removeCommunicationBinding(openDelete.item._id).then(() => {
      setOpenDelete({ item: null, isOpen: false });
    });
  };

  const handleBindingAdd = (data, closeDialog) => {
    const payload = {
      channelType: assistantType,
      isActive: true,
      ...data,
    };
    addCommunicationBinding(payload).then(closeDialog);
  };

  return (
    <PhoneNumberBinding
      isOpen={openDelete.isOpen}
      onClose={() => setOpenDelete({ isOpen: false, item: null })}
      onBindingDelete={(item) => setOpenDelete({ isOpen: true, item })}
      onBindingAdd={handleBindingAdd}
      onBindingConfirmDelete={handleBindingDelete}
      phoneNumbers={phoneNumbers}
      communicationBinding={communicationBinding}
    />
  );
}

export { VoiceCommunicationBinding };
